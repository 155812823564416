<template>
  <v-app dark>
    <app-menu/>
    <alert/>
    <v-content>
      <div class="pt-sm-12 pt-2">
        <nuxt/>
      </div>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import AppMenu from '~/layouts/partials/Menu';
import AppFooter from '~/layouts/partials/Footer';
import Alert from '~/layouts/partials/Alert';

export default {
  components: {
    Alert,
    AppMenu,
    AppFooter,
  },
};
</script>
