import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';

const bugsnagClient = bugsnag({
  apiKey: process.env.BUGSNAG_API_KEY,
  appVersion: process.env.RELEASE,
  notifyReleaseStages: ['production'],
});

bugsnagClient.use(bugsnagVue, Vue);

export default ({store}, inject) => {
  if (process.env.RELEASE) {
    bugsnagClient.app.version = process.env.RELEASE;
  }

  if (store.state.auth && store.state.auth.user) {
    bugsnagClient.user = {
      id: store.state.auth.user.uuid,
      name: store.state.auth.user.name,
    };
  }

  inject('bugsnag', bugsnagClient);
};
