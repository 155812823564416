import Vue from 'vue';
import DynamicForm from '~/components/form/DynamicForm';
import OnlyHtml from '~/components/form/OnlyHtml';

const stripLeftOverPs = (html) => {
  if (html.endsWith('<p>')) {
    html = html.substring(0, html.length - 3);
  }

  if (html.startsWith('</p>')) {
    return html.substring(4);
  }

  return html;
};

Vue.component(DynamicForm);

Vue.component('DynamicText', {
  props: {
    html: {
      type: String,
      required: true,
    },
  },
  render(createElement) {
    const formIds = [];
    const elements = [];
    const splitter = '<|||||>';

    if (!this.html) {
      return '';
    }

    const html = this.html.replace(/{{FORM-(\d+)}}/g, (match, id) => {
      formIds.push(parseInt(id));

      return splitter;
    });

    html.split(splitter).forEach((part, index) => {
      elements.push(
        createElement(OnlyHtml, {
          props: {
            html: stripLeftOverPs(part),
          },
        })
      );

      if (formIds[index]) {
        elements.push(
          createElement(DynamicForm, {
            props: {
              id: formIds[index],
            },
          })
        );
      }
    });

    return createElement('div', {}, elements);
  },
});
