import Vue from 'vue';
import {detect} from 'detect-browser';
const browser = detect();

const Browser = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        browser: browser || null,
      }),
    });
  },
};

Vue.use(Browser);
