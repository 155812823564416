export const state = () => ({
  release: process.env.RELEASE,
  settings: {},
  page: {},
  menuMain: {},
  menuFooter: {},
});

export const actions = {
  async nuxtServerInit({dispatch}) {
    await dispatch('storeDispatchFunc');
  },

  async storeDispatchFunc({commit}) {
    const [settings, menuMain, menuFooter] = await Promise.all([
      this.$axios.get('/settings'),
      this.$axios.get('/menu/main'),
      this.$axios.get('/menu/footer'),
    ]);

    commit('SET_SETTINGS', settings.data);
    commit('SET_MENU_MAIN', menuMain.data);
    commit('SET_MENU_FOOTER', menuFooter.data);
  },

  async getPage({commit}, slug = '') {
    const response = await this.$axios.get(`/page?segments=${slug}`);
    const page = response.data.data;

    commit('SET_PAGE', page);
  },
};

export const mutations = {
  SET_SETTINGS(state, payload) {
    state.settings = payload;
  },
  SET_PAGE(state, payload) {
    state.page = payload;
  },
  SET_MENU_MAIN(state, payload) {
    state.menuMain = payload;
  },
  SET_MENU_FOOTER(state, payload) {
    state.menuFooter = payload;
  },
};
