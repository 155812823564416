<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="8" lg="6">
        <template v-if="error.statusCode === 410 || error.statusCode === 404">
          <h1 class="display-3 mb-6">{{ page.title }}</h1>
          <!--eslint-disable-next-line-->
          <div v-html="page.tekst"/>
        </template>
        <h1 v-else>
          Er is een fout opgetreden
        </h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';

export default {
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: mapState(['page']),
  async created() {
    await this.$store.dispatch('getPage', '404');
  },
  head() {
    return {
      title: this.page.meta ? this.page.meta.title : this.page.title,
      meta: [
        this.page.meta && this.page.meta.description
          ? {
            hid: 'description',
            name: 'description',
            content: this.page.meta.description,
          }
          : {},
      ],
    };
  },
  mounted() {
    console.log(`[${this.error.statusCode || 0}] ${this.error.message}`);
    console.log(this.error);
  },
};
</script>
