export default {
  methods: {
    focusFirstError(component, offset = -240) {
      component.$nextTick(() => {
        const element = component.$el.querySelector('.error--text');

        if (!element) {
          return;
        }

        component.$vuetify.goTo(element, {
          offset: offset,
        });
      });
    },
  },
};
