<template>
  <v-footer color="black">
    <v-container>
      <v-row class="align-center">
        <v-col cols="12" sm="6" order="1">
          <span class="body-2">
            © {{ new Date().getFullYear() }} {{ appName }} -
            <nuxt-link v-for="(item, index) in menuSecond" :key="item.url" :to="item.url" class="white--text">{{ item.text }}<template v-if="index < (menuSecond.length - 1)"> - </template></nuxt-link>
          </span>
        </v-col>
        <v-col cols="12" sm="6" class="text-sm-right social-buttons" order="-1" order-sm="2">
          <v-btn
            v-for="channel in social"
            :key="channel.url"
            :href="channel.url"
            small
            fab
            tile
            target="_blank"
            rel="noopener noreferer nofollow"
            class="mr-2"
          >
            <font-awesome-icon :icon="channel.icon" color="white" size="2x"/>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import {mapState} from 'vuex';

export default {
  computed: {
    ...mapState({
      menuSecond: state => state.menuFooter,
      settings: state => state.settings,
    }),
    social() {
      return [
        {
          url: this.settings.facebook,
          icon: ['fab', 'facebook-f'],
        },
        {
          url: this.settings.twitter,
          icon: ['fab', 'twitter'],
        },
        {
          url: this.settings.instagram,
          icon: ['fab', 'instagram'],
        },
        {
          url: this.settings.youtube,
          icon: ['fab', 'youtube'],
        },
      ].filter((data) => {
        return data.url;
      });
    },
  },
};
</script>

<style lang="sass" scoped>

.social-buttons
  .theme--dark.v-btn
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5)

</style>
