<template>
  <!--  eslint-disable-next-line-->
  <div v-html="html"/>
</template>

<script>
export default {
  props: {
    html: {
      type: String,
      default: '',
    },
  },
};

</script>
