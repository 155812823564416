import Vue from 'vue';

Vue.prototype.$breakpoint = new Vue({
  data() {
    return {
      isMounted: false,
      vuetifyBreakpoint: null,
      default: {
        xs: true,
        xsOnly: true,
        xsAndUp: true,
        sm: false,
        smOnly: true,
        smAndDown: true,
        smAndUp: false,
        md: false,
        mdOnly: false,
        mdAndDown: true,
        mdAndUp: false,
        lg: false,
        lgOnly: false,
        lgAndDown: true,
        lgAndUp: false,
        xl: false,
        xlOnly: false,
        xlAndDown: true,
      },
    };
  },
  computed: {
    is() {
      if (!this.isMounted) {
        return this.$data.default;
      }

      return Object.keys(this.$data.default).reduce((breakpoints, dim) => {
        breakpoints[dim] = this.breakpointWithDefault(dim);
        return breakpoints;
      }, {});
    },
  },
  methods: {
    breakpointWithDefault(breakpoint) {
      return this.isMounted && this.vuetifyBreakpoint ? this.vuetifyBreakpoint[breakpoint] : this.$data.default[breakpoint];
    },
  },
});

export default ({app, $vuetify}) => {
  if (!app.mixins) {
    app.mixins = [];
  }

  app.mixins.push({
    mounted() {
      this.$breakpoint.$data.vuetifyBreakpoint = $vuetify.breakpoint;
      this.$breakpoint.$data.isMounted = true;
    },
  });
};
