import Vue from 'vue';

const appName = process.env.COMPANY || 'Bossify';
const appSlug = process.env.COMPANY_SLUG || process.env.COMPANY.toLowerCase();

const Config = {
  install(Vue) {
    Vue.mixin({
      data: () => ({
        appName,
        appSlug,
        appLogo: require(process.env.LOGO || '~/assets/img/logo.svg'),
        dashboardBaseUrl: process.env.DASHBOARD_URL,
      }),
      methods: {
        dashboardUrl(path) {
          return this.dashboardBaseUrl + path;
        },
      },
    });
  },
};

Vue.use(Config);

export default ({app, $vuetify}) => {
  if (!app.mixins) {
    app.mixins = [];
  }

  app.mixins.push({
    mounted() {
      const root = document.documentElement;

      Object.keys(this.$vuetify.theme.themes.dark).forEach((color) => {
        root.style.setProperty(`--${color}-rgb`, this.hexToRGB($vuetify.theme.themes.dark[color]));
      });

      if (appSlug) {
        root.classList.add(appSlug);
      }
    },
    methods: {
      hexToRGB(h) {
        let r = 0;
        let g = 0;
        let b = 0;

        // 3 digits
        if (h.length === 4) {
          r = '0x' + h[1] + h[1];
          g = '0x' + h[2] + h[2];
          b = '0x' + h[3] + h[3];

          // 6 digits
        } else if (h.length === 7) {
          r = '0x' + h[1] + h[2];
          g = '0x' + h[3] + h[4];
          b = '0x' + h[5] + h[6];
        }

        return +r + ',' + +g + ',' + +b;
      },
    },
  });
};
