<template>
  <v-app dark>
    <app-menu/>
    <alert/>
    <v-content class="overflow-x-hidden">
      <nuxt/>
    </v-content>
    <app-footer/>
  </v-app>
</template>

<script>
import AppMenu from '~/layouts/partials/Menu';
import AppFooter from '~/layouts/partials/Footer';
import Alert from '~/layouts/partials/Alert';

export default {
  components: {
    Alert,
    AppMenu,
    AppFooter,
  },
};
</script>
