import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _61995c0f = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _a723b9c8 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _2d82a926 = () => interopDefault(import('../pages/prijzen.vue' /* webpackChunkName: "pages/prijzen" */))
const _5fe2bac7 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _0a57c150 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _125d31b0 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _61995c0f,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/faq",
    component: _a723b9c8,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/prijzen",
    component: _2d82a926,
    pathToRegexpOptions: {"strict":true},
    name: "prijzen"
  }, {
    path: "/blog/:slug",
    component: _5fe2bac7,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/",
    component: _0a57c150,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/*",
    component: _125d31b0,
    pathToRegexpOptions: {"strict":true},
    name: "*"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
