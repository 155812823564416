import Vue from 'vue';

Vue.filter('currency', val =>
  parseFloat(val)
    .toFixed(2)
    .replace('.', ',')
    .replace(',00', ',-')
);

Vue.filter('ucFirst', val =>
  val.charAt(0).toUpperCase() + val.slice(1)
);

Vue.filter('moment', (val, format) => {
  // Vue.prototype.$moment(val).format(format)
});
