export default ({app, route, isDev}) => {
  if (route.query && route.query.via) {
    const appSlug = process.env.COMPANY_SLUG || process.env.COMPANY.toLowerCase();
    const domain = `${appSlug}.nl`;

    const expires = new Date();
    expires.setMonth(expires.getMonth() + 1);

    app.$cookies.set('via', route.query.via, {
      domain: isDev ? undefined : domain,
      expires,
    });
  }
};
