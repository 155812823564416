<template>
  <div class="align-self-start justify-end d-flex d-sm-none">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{on}">
        <div class="d-flex menu pt-2">
          <v-btn
            :ripple="false"
            v-on="on"
            icon
            x-small
            color="white"
          >
            <font-awesome-icon v-if="dialog" :icon="['fal', 'times']" class="white--text" size="2x"/>
            <img v-else src="~/assets/img/icons/menu.svg" alt="Menu openen">
            <span class="text-uppercase">{{ dialog ? 'Sluiten' : 'Menu' }}</span>
          </v-btn>
        </div>
      </template>
      <v-card class="text-center background lighten-3">
        <v-row class="flex-column" style="height: calc(100vh - 60px);">
          <v-col class="d-flex flex-column justify-center">
            <v-list class="px-3 transparent">
              <v-list-item
                v-for="item in menu"
                :to="item.url"
                :key="item.text"
                :ripple="false"
                v-text="item.text"
                @click="dialog = false"
                flat
                class="caption text-uppercase underline"
                nuxt
              />
            </v-list>
          </v-col>
          <v-col class="background lighten-2 shadow shrink py-12">
            <v-btn
              :href="dashboardUrl('/registreren')"
              depressed
              color="accent"
              class="white--text"
            >
              Probeer gratis
            </v-btn>
            <div class="pt-4">
              <span class="overline white--text text-uppercase font-weight-bold pr-2">Heb je al een account?</span>
              <v-btn :href="dashboardUrl('/inloggen')" text color="primary" class="overline text-uppercase font-weight-bold px-0">Inloggen</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'MobileMenu',
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
  }),
  computed: {
    ...mapState({
      menu: state => state.menuMain,
    }),
  },
};
</script>

<style lang="sass" scoped>
.shadow
  box-shadow: 0 0 25px 0 #000000

.underline
  position: relative

  &::before
    content: ''
    position: absolute
    top: 45px
    left: 16px
    right: 16px
    height: 1px
    opacity: 0.4 !important
    background-color: var(--v-accent-base)

::v-deep .menu
  img
    max-width: 23px
    padding-bottom: 2px

  .v-btn__content
    justify-content: flex-end
    flex-direction: column
</style>
