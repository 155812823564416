<template>
  <v-app-bar app flat color="background">
    <v-container>
      <v-row class="align-center justify-space-between">
        <v-col class="pa-0 pa-sm-3">
          <nuxt-link to="/" class="d-block">
            <v-img :src="appLogo" :alt="appName" :height="35" contain/>
          </nuxt-link>
        </v-col>
        <v-col class="d-none d-sm-block">
          <v-toolbar-items class="d-flex">
            <v-btn
              v-for="item in menu"
              :to="item.url"
              :key="item.text"
              :ripple="false"
              v-text="item.text"
              text
              color="white"
              class="font-weight-bold"
              nuxt
            />
          </v-toolbar-items>
        </v-col>
        <v-col class="text-right d-none d-sm-block">
          <v-btn :href="dashboardUrl('/inloggen')" class=" mr-4 d-inline-flex" text color="white">
            Inloggen
          </v-btn>
          <v-btn
            :href="dashboardUrl('/registreren')"
            depressed
            color="primary"
            class="white--text d-none d-sm-inline-flex"
          >
            Probeer gratis
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <mobile-menu/>
  </v-app-bar>
</template>

<script>
import {mapState} from 'vuex';
import MobileMenu from '~/components/index/MobileMenu';

export default {
  components: {
    MobileMenu,
  },
  computed: {
    ...mapState({
      menu: state => state.menuMain,
    }),
  },
};
</script>

<style lang="sass" scoped>
::v-deep .v-image__image
  background-position: left center !important
</style>
