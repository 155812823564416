<template>
  <v-app dark>
    <snackbar/>
    <alert/>
    <v-content>
      <nuxt/>
    </v-content>
    <app-footer v-if="$route.name === 'privacy'"/>
  </v-app>
</template>

<script>
import Snackbar from '~/layouts/partials/Snackbar';
import Alert from '~/layouts/partials/Alert';
import AppFooter from '~/layouts/partials/Footer';

export default {
  components: {
    Snackbar, Alert, AppFooter,
  },
};
</script>
