import Vue from 'vue';
import {library, config} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {
  faChevronDown as fasFaChevronDown,
  faInfoCircle,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';

import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import {
  faLineColumns,
  faCheckSquare,
  faChevronRight as fasFaChevronRight,
  faChevronLeft as fasFaChevronLeft,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faSearch,
  faMinus,
  faCalendarAlt,
  faCheckCircle,
  faEye,
  faEyeSlash,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faExclamationTriangle,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faPlusCircle,
  faDownload,
  faClone,
  faTrashAlt,
  faChevronDown as falFaChevronDown,
  faChevronRight,
  faChevronLeft,
  faMinusSquare,
  faSquare,
  faChevronUp,
  faEdit,
  faEllipsisH,
  faSignOutAlt,
  faUser,
  faFile,
  faTimesCircle,
  faMapMarkerAlt,
  faPlus,
  faTimes,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons';

config.autoAddCss = false;

library.add(
  faPlusCircle,
  faTrashAlt,
  faDownload,
  faClone,
  faTrashAlt,
  falFaChevronDown,
  fasFaChevronDown,
  faFacebookF,
  faChevronRight,
  faChevronLeft,
  faAngleDown,
  faCheckCircle,
  faSquare,
  faMinusSquare,
  faMinus,
  faChevronUp,
  faEdit,
  faSearch,
  faPlus,
  faInfoCircle,
  faTimes,
  faTwitter,
  faCaretDown,
  faEllipsisH,
  faSignOutAlt,
  faUser,
  faFile,
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faTimesCircle,
  faLineColumns,
  faCheckSquare,
  faMapMarkerAlt,
  fasFaChevronRight,
  fasFaChevronLeft,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faInstagram,
  faTwitter,
  faYoutube,
  faExclamationTriangle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
