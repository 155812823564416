<template>
  <div>
    <v-alert :value="success" :icon="false" type="success" tile>The form is successfully submitted.</v-alert>
    <form v-if="!success" @submit.prevent="submit" method="post" class="mb-3">
      <div v-for="field in template.fields" :key="field.position">
        <v-text-field
          v-if="field.type === 'text'"
          :id="field.name + '_' + id"
          v-model="form[field.name]"
          :error-messages="errors[field.name]"
          :label="field.label"
          :required="!!field.required"
          filled
        />
        <v-text-field
          v-if="field.type === 'email'"
          :id="field.name + '_' + id"
          v-model="form[field.name]"
          :error-messages="errors[field.name]"
          :label="field.label"
          :required="!!field.required"
          type="email"
          filled
        />
        <v-text-field
          v-if="field.type === 'number'"
          :id="field.name + '_' + id"
          v-model="form[field.name]"
          :error-messages="errors[field.name]"
          :label="field.label"
          :required="!!field.required"
          type="number"
          filled
        />
        <v-select
          v-if="field.type === 'select'"
          :id="field.name + '_' + id"
          :items="field.options"
          :error-messages="errors[field.name]"
          :label="field.label"
          :required="!!field.required"
          filled
        />
        <v-textarea
          v-if="field.type === 'textarea'"
          :id="field.name + '_' + id"
          v-model="form[field.name]"
          :error-messages="errors[field.name]"
          :label="field.label"
          :required="!!field.required"
          filled
          no-resize
        />
        <template v-if="field.type === 'checkbox'">
          <p>{{ field.label }}</p>
          <v-checkbox
            v-for="(option, index) in field.options"
            :key="index"
            v-model="form[field.name]"
            :label="option"
            :value="option"
            :hide-details="index < (field.options.length - 1)"
            :error="errors[field.name] && (errors[field.name].length > 0)"
            filled
          />
        </template>
      </div>
      <v-btn
        v-if="template.fields"
        color="primary"
        type="submit"
      >
        Send
      </v-btn>
    </form>
  </div>
</template>

<script>
import commonForm from '~/plugins/mixins/form';

export default {
  mixins: [commonForm],
  props: {
    id: {
      type: Number,
      default: 0,
    },
    html: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      success: false,
      template: {},
      form: {},
    };
  },
  async mounted() {
    const fields = await this.$axios.get('/forms/' + this.id);

    this.template = fields.data.data;

    // Set defaults
    const that = this;
    this.template.fields.forEach((field) => {
      if (field.type === 'checkbox') {
        // Default must be array to work
        that.form[field.name] = [];
      }
    });
  },
  methods: {
    async submit() {
      try {
        await this.$axios.post('/forms/' + this.id, this.form);

        this.success = true;
      } catch (e) {
        this.focusFirstError(this);
      }
    },
  },
};
</script>

<style scoped lang="sass">
  ::v-deep .v-text-field > .v-input__control > .v-input__slot:before
    border-width: 0
</style>
